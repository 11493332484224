import { Component, OnInit } from '@angular/core';
import { Animations } from 'src/app/app.animations';

@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss'],
  animations: [ Animations.slider ]
})
export class RoutingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
