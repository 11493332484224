import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampionInGameComponent } from './champion-in-game.component';
import { DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InGameHeaderModule } from '../presenters/in-game-header/in-game-header.module';
import { BuildModule } from '../presenters/build/build.module';


@NgModule({
  declarations: [ChampionInGameComponent],
  imports: [
    CommonModule,
    FormsModule,
    BuildModule,
    InGameHeaderModule,
    DropdownModule
  ],
  exports: [ChampionInGameComponent]
})
export class ChampionInGameModule { }
