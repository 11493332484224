import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss']
})
export class BuildComponent implements OnInit, OnChanges {

  @Input() selectedChamp: any;
  @Input() pos: string;
  public build: Array<any>;
  constructor() { }

  ngOnInit(): void {
    if (this.selectedChamp) {
      this.build = this.selectedChamp.recommended.filter(x => x.map === 'SR' && x.mode === 'CLASSIC')[0].blocks;
    }
    console.log(this.build);
  }

  ngOnChanges(): void {
    console.log(this.pos);
  }

}
