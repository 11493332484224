import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LolState, selectChampDetails, selectVersion } from '../../store/league-of-legends.selector';

@Component({
  selector: 'app-champion-details',
  templateUrl: './champion-details.component.html',
  styleUrls: ['./champion-details.component.scss']
})
export class ChampionDetailsComponent implements OnInit {

  public link = faExternalLinkSquareAlt;
  @Input() inGameChamp: boolean;
  public selectedChamp: any;
  public version: Observable<string>;
  constructor(private store: Store<LolState>) { }

  ngOnInit(): void {
    this.selectedChamp = this.store.pipe(select(selectChampDetails));
    this.version = this.store.pipe(select(selectVersion));
  }

  showInGameChamp() {
    this.inGameChamp = true;
  }

  handleBack(val): void {
    if (val === true) {
      this.inGameChamp = false;
    }
  }

}
