import { Action } from '@ngrx/store';

export enum ChampionsActionsTypes {

    GetVersion = '[Champions Action] Get Version',
    GetVersionSuccess = '[Champions Action] Get Version Success',
    GetVersionFailure = '[Champions Action] Get Version Failure',

    GetChampions = '[Champions Action] Get Champions',
    GetChampionsSuccess = '[Champions Action] Get Champions Success',
    GetChampionsFailure = '[Champions Action] Get Champions Failure',

    GetChampionsDetails = '[Champions Action] Get Champions Details',
    GetChampionsDetailsSuccess = '[Champions Action] Get Champions Details Success',
    GetChampionsDetailsFailure = '[Champions Action] Get Champions Details Failure',

    SetLangage = '[Champions Action] Set Langage',

    FilterChamps = '[Champions Action] Filter Champs',
    ClearSelectedChamp = '[Champions Action] Clear Selected Champ'
}
export class GetChampions implements Action {
    readonly type = ChampionsActionsTypes.GetChampions;
    constructor(public lang: string) { }
}
export class GetChampionsSuccess implements Action {
    readonly type = ChampionsActionsTypes.GetChampionsSuccess;
    constructor(public championsData: any) { }
}
export class GetChampionsFailure implements Action {
    readonly type = ChampionsActionsTypes.GetChampionsFailure;
    constructor() { }
}

export class GetChampionsDetails implements Action {
    readonly type = ChampionsActionsTypes.GetChampionsDetails;
    constructor(public champName: string, public version: string, public lang: string) { }
}
export class GetChampionsDetailsSuccess implements Action {
    readonly type = ChampionsActionsTypes.GetChampionsDetailsSuccess;
    constructor(public champName: string, public champData: any) { }
}
export class GetChampionsDetailsFailure implements Action {
    readonly type = ChampionsActionsTypes.GetChampionsDetailsFailure;
    constructor() { }
}

export class GetVersion implements Action {
    readonly type = ChampionsActionsTypes.GetVersion;
    constructor() { }
}
export class GetVersionSuccess implements Action {
    readonly type = ChampionsActionsTypes.GetVersionSuccess;
    constructor(public version: string) { }
}
export class GetVersionFailure implements Action {
    readonly type = ChampionsActionsTypes.GetVersionFailure;
    constructor() { }
}

export class SetLangage implements Action {
    readonly type = ChampionsActionsTypes.SetLangage;
    constructor(public payload: string) { }
}

export class FilterChamps implements Action {
    readonly type = ChampionsActionsTypes.FilterChamps;
    constructor(public payload: string) { }
}

export class ClearSelectedChamp implements Action {
    readonly type = ChampionsActionsTypes.ClearSelectedChamp;
    constructor() { }
}

export type ChampionsActions = GetChampions | GetChampionsSuccess | GetChampionsFailure |
                                GetChampionsDetails | GetChampionsDetailsSuccess | GetChampionsDetailsFailure |
                                GetVersion | GetVersionSuccess | GetVersionFailure |
                                SetLangage |
                                FilterChamps | ClearSelectedChamp;
