import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampionListComponent } from './champion-list.component';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromStore from '../store/league-of-legends.selector';
import { EffectsModule } from '@ngrx/effects';
import { LeagueOfLegendsEffect } from '../effects/league-of-legends.effects';
import { FormsModule } from '@angular/forms';
import { ChampionDetailsModule } from '../champion-details/page/champion-details.module';
import {DialogModule} from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeagueOfLegendsService } from '../services/league-of-legends.service';
import { LolHeaderModule } from '../lol-header/lol-header.module';

@NgModule({
  declarations: [ChampionListComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    DialogModule,
    LolHeaderModule,
    FormsModule,
    StoreModule.forRoot({}),
    ChampionDetailsModule,
    StoreModule.forFeature(fromStore.featureName, fromStore.reducers, { metaReducers: fromStore.metaReducers }),
    EffectsModule.forFeature([LeagueOfLegendsEffect]),
  ],
  providers: [LeagueOfLegendsService],
  exports: [ChampionListComponent]
})
export class ChampionListModule { }
