import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildComponent } from './build.component';



@NgModule({
  declarations: [BuildComponent],
  imports: [
    CommonModule
  ],
  exports: [BuildComponent]
})
export class BuildModule { }
