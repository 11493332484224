import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { SkillsModule } from '../presenters/skills/skills.module';
import { ExperienceModule } from '../presenters/experience/experience.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [AboutComponent],
  imports: [
    CommonModule,
    SkillsModule,
    ExperienceModule,
    RouterModule,
  ],
  exports: [AboutComponent]
})
export class AboutModule { }
