import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FilterChamps, GetChampions, SetLangage } from '../league-of-legends/store/actions/champions.actions';
import { LolState } from '../league-of-legends/store/league-of-legends.selector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public previousExist: boolean;
  constructor(private location: Location, private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(x => {
      this.previousExist = this.location.isCurrentPathEqualTo('/landing');
    })
  }

  previous() {
    this.location.back();
  }

}
