import { ChampionsActions, ChampionsActionsTypes } from '../actions/champions.actions';
import * as R from 'ramda';
import { ChampData } from '../../models/lol.model';

export const initialState: ChampData =  {
    version: '',
    champions: {},
    filteredChampionList: {},
    champDetails: null,
    langage: 'fr_FR'
};

export function championsReducer(state = initialState, action: ChampionsActions): ChampData {
  switch (action.type) {

    case ChampionsActionsTypes.GetChampions:
        return state;
    case ChampionsActionsTypes.GetChampionsSuccess:
        return {...state, champions: action.championsData, filteredChampionList: action.championsData.data};
    case ChampionsActionsTypes.GetChampionsFailure:
        return state;

    case ChampionsActionsTypes.GetChampionsDetails:
        return state;
    case ChampionsActionsTypes.GetChampionsDetailsSuccess:
        return {...state, champDetails: action.champData.data[action.champName]};
    case ChampionsActionsTypes.GetChampionsDetailsFailure:
        return state;

    case ChampionsActionsTypes.GetVersion:
        return state;
    case ChampionsActionsTypes.GetVersionSuccess:
        return {...state, version: action.version};
    case ChampionsActionsTypes.GetVersionFailure:
        return state;

    case ChampionsActionsTypes.SetLangage:
        return {...state, langage: action.payload};
    case ChampionsActionsTypes.FilterChamps:
        return {...state,
            filteredChampionList: R.fromPairs(
                    R.toPairs(state.champions.data).filter(x => x[1].name.toLowerCase().includes(action.payload.toLowerCase()))
                )
        }
    case ChampionsActionsTypes.ClearSelectedChamp:
        return {...state, champDetails: null}
    default:
        return state;
  }
}
