import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LolHeaderComponent } from './lol-header.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [LolHeaderComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
  ],
  exports: [LolHeaderComponent]
})
export class LolHeaderModule { }
