import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampInfosComponent } from './champ-infos.component';



@NgModule({
  declarations: [ChampInfosComponent],
  imports: [
    CommonModule
  ],
  exports: [ChampInfosComponent]
})
export class ChampInfosModule { }
