import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import * as fromStore from './modules/league-of-legends/store/league-of-legends.selector';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HeaderModule } from './modules/header/header.module';
import { LeagueOfLegendsService } from './modules/league-of-legends/services/league-of-legends.service';
import { RoutingModule } from './modules/routing/routing.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    HeaderModule,
    BrowserAnimationsModule,
    RoutingModule,
    CommonModule,
    RouterModule.forRoot([]),
    StoreModule.forRoot(fromStore.reducers, { metaReducers: fromStore.metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    FontAwesomeModule,
  ],
  providers: [LeagueOfLegendsService],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
