import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class LeagueOfLegendsService {
  constructor(private http: HttpClient) {

  }

  public getVersion(): Observable<any> {
    return this.http.get('https://ddragon.leagueoflegends.com/api/versions.json').pipe(
      map(resp => resp[0])
    );
  }

  public getChampions(lang: string): Observable<any> {
    return this.http.get('https://ddragon.leagueoflegends.com/api/versions.json').pipe(
      mergeMap(response => this.http.get(`http://ddragon.leagueoflegends.com/cdn/${response[0]}/data/${lang}/champion.json`))
    );
  }

  public getChampionDetails(championName: string, version: string, lang: string): Observable<any> {
    return this.http.get(`http://ddragon.leagueoflegends.com/cdn/${version}/data/${lang}/champion/${championName}.json`)
  }

}
