import { Component, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LolState, selectChampDetails } from '../../store/league-of-legends.selector';

@Component({
  selector: 'app-champion-in-game',
  templateUrl: './champion-in-game.component.html',
  styleUrls: ['./champion-in-game.component.scss']
})
export class ChampionInGameComponent implements OnInit {

  public selectedChamp: Observable<any>;
  public selectedPos = '';
  @Output() back: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private store: Store<LolState>) { }

  ngOnInit(): void {
    this.selectedChamp = this.store.pipe(select(selectChampDetails));

  }

  handleBack(val): void {
    if (val === true) {
      this.back.next(true);
    }
  }
}
