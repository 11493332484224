import {
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
  } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { ChampData } from '../models/lol.model';
import { championsReducer } from './reducers/champions-reducer';


export const featureName = 'champion-list';

export interface LolState {
    data: ChampData;
}

export const reducers: ActionReducerMap<LolState> = {
    data: championsReducer,
};


export const metaReducers: MetaReducer<LolState>[] = !environment.production ? [] : [];
export const lolData = createFeatureSelector<LolState>(featureName);
export const selectChampsData = createSelector(lolData, (state) => state.data.champions);
export const selectFilteredChampList = createSelector(lolData, (state) => state.data.filteredChampionList)

export const selectVersion = createSelector(lolData, (state) => state.data.version);

export const selectChampDetails = createSelector(lolData, (state) => state.data.champDetails);
export const selectLang = createSelector(lolData, (state) => state.data.langage);
