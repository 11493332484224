import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ClearSelectedChamp, GetChampions, GetChampionsDetails, GetVersion } from '../store/actions/champions.actions';
import { LolState, selectChampDetails, selectFilteredChampList, selectLang, selectVersion } from '../store/league-of-legends.selector';

@Component({
  selector: 'app-champion-list',
  templateUrl: './champion-list.component.html',
  styleUrls: ['./champion-list.component.scss']
})
export class ChampionListComponent implements OnInit, OnDestroy {

  public displayPopup = false;
  public versionObs: Observable<string>;
  public versionSubscription: Subscription;
  public version: string;
  public langSubscription: Subscription;
  public lang: string;
  public champions: Observable<any>;
  public selectedChamp: Observable<any>;
  constructor(private store: Store<LolState>) { }

  ngOnInit() {
    this.store.dispatch(new GetVersion());
    this.langSubscription = this.store.pipe(select(selectLang)).subscribe(x => this.lang = x);
    this.store.dispatch(new GetChampions(this.lang));
    this.champions = this.store.pipe(select(selectFilteredChampList));
    this.versionObs = this.store.pipe(select(selectVersion));
    this.versionSubscription = this.versionObs.subscribe(x => this.version = x);
    this.selectedChamp = this.store.pipe(select(selectChampDetails));
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
    this.versionSubscription.unsubscribe();
  }

  selectChamp(name) {
    this.displayPopup = true;
    this.store.dispatch(new GetChampionsDetails(name, this.version, this.lang));
  }

  clearSelectedChamp() {
    this.displayPopup = false;
    this.store.dispatch(new ClearSelectedChamp());
  }

}
