import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-champ-carousel',
  templateUrl: './champ-carousel.component.html',
  styleUrls: ['./champ-carousel.component.scss']
})
export class ChampCarouselComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() selectedChamp: any;
  @Input() version: string;
  public isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public skins: Array<{
    previewImageSrc: string;
    thumbnailImageSrc: string,
    alt: string,
    title: string
  }>;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    interval(500).pipe(take(1)).subscribe(() => this.isLoaded.next(true));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.selectedChamp.skins) {
      this.skins = this.selectedChamp.skins.map(skin => {
        return {
          previewImageSrc: `http://ddragon.leagueoflegends.com/cdn/img/champion/splash/${this.selectedChamp.id}_${skin.num}.jpg`,
          thumbnailImageSrc: `https://cdn.communitydragon.org/${this.version}/champion/${this.selectedChamp.key}/tile/skin/${skin.num}`,
          alt: skin.name,
          title: skin.name
        }
      })
    }
  }

}
