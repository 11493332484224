import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  public experience: Array<{name: string, details: string, start: string, end: string}>;
  constructor() { }

  ngOnInit(): void {
    this.experience = [
      {
        name: 'MGI Digital Technology',
        details: 'For about 2 years, I worked in the company MGI Digital Technology. I integrated a team of 4 to 5 developers as a Front-End Angular developer with the objective of refactoring the IHM of every printers build by the company.\n\
                  For those 2 years, I learned to work on enormous projects as a team with responsability. Documenting my code and separating my tasks. I learned a lot about Angular and differents library to use, be it RxJS, NgRX, FontAwesome or PrimeNg.',
        start: 'April 2019',
        end: 'Now'
      },
      {
        name: 'Meltdown Esports Bar',
        details: 'Dans le contexte de mes études à l\'école 42, j\'ai été amené à réliser un stage de 6 mois dans le domaine de mon choix. Voulant m\'orienter dans le web, je suis allé faire mon stage au sein des bars Meltdown, qui sont des bars "gaming" présent mondialement.\n\
                  Durant ce stage, mon travail a surtout consisté à intégrer les différents designs réalisé par le/les designer de la boîte, et à corriger des bugs sur les sites déjà existant. J\'ai donc eu l\'occasion de travailler sur le site https://www.meltdown.bar/, ou encore le site https://www.tribe.clubn\
                  La stack utilisée était cette fois bien plus simple puisque l\'on travaillait seulement en HTML/CSS/JS.',
        start: 'June 2018',
        end: 'December 2018'
      }
    ]
  }

}
