import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingComponent } from './routing.component';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { ChampionListComponent } from '../league-of-legends/champion-list/champion-list.component';
import { ProjectListComponent } from '../project-list/project-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ProjectListModule } from '../project-list/project-list.module';
import { ChampionListModule } from '../league-of-legends/champion-list/champion-list.module';
import { AboutComponent } from '../about/pages/about.component';
import { AboutModule } from '../about/pages/about.module';
import { LandingPageModule } from '../landing-page/landing-page.module';
import { ContactComponent } from '../contact/contact.component';
import { ContactModule } from '../contact/contact.module';


export const appRouteList: Routes = [
  {
      path: 'landing',
      component: LandingPageComponent,
      data: {animation: 0}
  },
  {
      path: 'projects',
      data: { animation: 1 },
      component: ProjectListComponent
  },
  {
      path: 'about',
      data: { animation: 2 },
      component: AboutComponent
  },
  {
      path: 'league-of-legends',
      component: ChampionListComponent
  },
  {
    path: 'petit-bac',
    redirectTo: 'landing'
  },
  {
      path: '**',
      redirectTo: 'landing'
  }
];

@NgModule({
  declarations: [RoutingComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ContactModule,
    BrowserAnimationsModule,
    LandingPageModule,
    ChampionListModule,
    RouterModule,
    AboutModule,
    ProjectListModule,
    RouterModule.forRoot(appRouteList),
  ],
  exports: [RoutingComponent]
})
export class RoutingModule { }
