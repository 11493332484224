import { Component, Input, OnInit, Output } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-in-game-header',
  templateUrl: './in-game-header.component.html',
  styleUrls: ['./in-game-header.component.scss']
})
export class InGameHeaderComponent implements OnInit {

  @Output() selectedPos: BehaviorSubject<string> = new BehaviorSubject<string>('Mid');
  public link = faArrowLeft;
  @Input() championDetails: any;
  public pos: Array<{label: string, value: string}>;
  public selectedRank: any;
  public winrate: number = parseFloat(((Math.random() * 100.00) + 1.00).toFixed(2));
  public rank: Array<{label: string, value: string, icon: string}>;
  @Output() back: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  ngOnInit(): void {
    this.pos = [
    {
      label: 'Top',
      value: 'Top'
    },
    {
      label: 'Jungle',
      value: 'Jungle'
    },
    {
      label: 'Mid',
      value: 'Mid'
    },
    {
      label: 'Adc',
      value: 'Adc'
    },
    {
      label: 'Support',
      value: 'Support'
    },
  ];
  this.rank = [
    {
      label: 'Challenger',
      value: 'Challenger',
      icon: 'Emblem_Challenger.png'
    },
    {
      label: 'Grand Master',
      value: 'Grand Master',
      icon: 'Emblem_Grandmaster.png'
    },
    {
      label: 'Master',
      value: 'Master',
      icon: 'Emblem_Master.png'
    },
    {
      label: 'Diamond',
      value: 'Diamond',
      icon: 'Emblem_Diamond.png'
    },
    {
      label: 'Platinum',
      value: 'Platinum',
      icon: 'Emblem_Platinum.png'
    },
    {
      label: 'Gold',
      value: 'Gold',
      icon: 'Emblem_Gold.png'
    },
    {
      label: 'Silver',
      value: 'Silver',
      icon: 'Emblem_Silver.png'
    },
    {
      label: 'Bronze',
      value: 'Bronze',
      icon: 'Emblem_Bronze.png'
    },
    {
      label: 'Iron',
      value: 'Iron',
      icon: 'Emblem_Iron.png'
    },
  ]}

  previous(): void {
    this.back.next(true);
  }

  selectPos(val) {
    this.selectedPos.next(val);
  }
}
