import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampionDetailsComponent } from './champion-details.component';
import { ChampInfosModule } from '../presenters/champ-infos/champ-infos.module';
import { ChampCarouselModule } from '../presenters/champ-carousel/champ-carousel.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ChampionInGameModule } from '../../champion-in-game/page/champion-in-game.module';

@NgModule({
  declarations: [ChampionDetailsComponent],
  imports: [
    CommonModule,
    ChampInfosModule,
    ChampionInGameModule,
    ChampCarouselModule,
    FontAwesomeModule,
  ],
  exports: [ChampionDetailsComponent]
})
export class ChampionDetailsModule { }
