import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { LeagueOfLegendsService } from '../services/league-of-legends.service';
import { ChampionsActions,
    ChampionsActionsTypes,
    GetChampions,
    GetChampionsDetails,
    GetChampionsDetailsFailure,
    GetChampionsDetailsSuccess,
    GetChampionsFailure,
    GetChampionsSuccess,
    GetVersion,
    GetVersionFailure,
    GetVersionSuccess
} from '../store/actions/champions.actions';

@Injectable()
export class LeagueOfLegendsEffect {
  constructor(private actions$: Actions,
              private leagueOfLegendsService: LeagueOfLegendsService) {}

    @Effect()
    getVersion: Observable<ChampionsActions> = this.actions$.pipe(
    ofType<GetVersion>(ChampionsActionsTypes.GetVersion),
    mergeMap((action) => {
        return this.leagueOfLegendsService.getVersion().pipe(
        map((resp) => new GetVersionSuccess(resp)),
        catchError(() => of(new GetVersionFailure()))
        );
    })
    );
    @Effect()
    getChampionsData: Observable<ChampionsActions> = this.actions$.pipe(
    ofType<GetChampions>(ChampionsActionsTypes.GetChampions),
    mergeMap((action) => {
        return this.leagueOfLegendsService.getChampions(action.lang).pipe(
        map((resp) => new GetChampionsSuccess(resp)),
        catchError(() => of(new GetChampionsFailure()))
        );
    })
    );
    @Effect()
    getChampionDetails: Observable<ChampionsActions> = this.actions$.pipe(
    ofType<GetChampionsDetails>(ChampionsActionsTypes.GetChampionsDetails),
    mergeMap((action) => {
        return this.leagueOfLegendsService.getChampionDetails(action.champName, action.version, action.lang).pipe(
        map((resp) => new GetChampionsDetailsSuccess(action.champName, resp)),
        catchError(() => of(new GetChampionsDetailsFailure()))
        );
    })
    );
}
