import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page.component';
import { HeaderModule } from '../header/header.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    HeaderModule,
    FontAwesomeModule,
    CommonModule,
    RouterModule,
  ],
  exports: [LandingPageComponent]
})
export class LandingPageModule { }
