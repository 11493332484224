import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterChamps, GetChampions, SetLangage } from '../store/actions/champions.actions';
import { LolState } from '../store/league-of-legends.selector';

@Component({
  selector: 'app-lol-header',
  templateUrl: './lol-header.component.html',
  styleUrls: ['./lol-header.component.scss']
})
export class LolHeaderComponent implements OnInit {

  public langs = [
    {label: 'EN', value: 'en_US'},
    {label: 'CZ', value: 'cs_CZ'},
    {label: 'DE', value: 'de_DE'},
    {label: 'GR', value: 'el_GR'},
    {label: 'AU', value: 'en_AU'},
    {label: 'GB', value: 'en_GB'},
    {label: 'PH', value: 'en_PH'},
    {label: 'SG', value: 'en_SG'},
    {label: 'AR', value: 'es_AR'},
    {label: 'ES', value: 'es_ES'},
    {label: 'MX', value: 'es_MX'},
    {label: 'FR', value: 'fr_FR'},
    {label: 'HU', value: 'hu_HU'},
    {label: 'ID', value: 'id_ID'},
    {label: 'IT', value: 'it_IT'},
    {label: 'JP', value: 'ja_JP'},
    {label: 'KR', value: 'ko_KR'},
    {label: 'PL', value: 'pl_PL'},
    {label: 'BR', value: 'pt_BR'},
    {label: 'RO', value: 'ro_RO'},
    {label: 'RU', value: 'ru_RU'},
    {label: 'TH', value: 'th_TH'},
    {label: 'TR', value: 'tr_TR'},
    {label: 'VN', value: 'vn_VN'},
    {label: 'CN', value: 'zh_CN'},
    {label: 'MY', value: 'zh_MY'},
    {label: 'TW', value: 'zh_TW'},
  ]
  public selectedLang = 'fr_FR';
  constructor(private store: Store<LolState>) { }

  ngOnInit(): void {
  }

  filterChamps(val) {
    this.store.dispatch(new FilterChamps(val));
  }

  changeLang(val) {
    this.store.dispatch(new GetChampions(val));
    this.store.dispatch(new SetLangage(val));
  }

}
