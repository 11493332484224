import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  constructor(private router: Router) { }
  public projectList: Array<{name: string, url?: string, externalUrl?: string, image: string}>;

  ngOnInit(): void {
    this.projectList = [
      {
        name: 'Online Resume !',
        externalUrl: 'https://www.online-resume.phamelin.fr',
        image: 'assets/projects-preview/resume.png'
      },
      {
        name: 'Champion Selector (WIP)',
        url: '/league-of-legends',
        image: 'assets/projects-preview/champ-selector.png'
      },
      {
        name: 'Drout It (COMING SOON)',
        externalUrl: 'https://www.drou-it.phamelin.fr',
        image: 'assets/projects-preview/drou-it.png'
      },
      {
        name: 'Online Petit Baccalauréat (COMING SOON)',
        url: '/online-bac',
        image: 'assets/projects-preview/test.jpg'
      },
    ]
  }
}
