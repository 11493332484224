import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public start_animation: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  scroll(el: HTMLElement) {
    console.log(el);
    el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

}
