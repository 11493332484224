import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  public skillList: Array<{name: string, details: string, showDetails: boolean, icon: string}>;
  public level: Array<number>;
  public showDetails: boolean = false;
  public details: string = "I've been using Angular for 2 years now. Mostly using Angular 8. I know about the data flow and immutability, attributes and directive, component life cycle observable, presenters and containers, routing or pipes... I still got a lot to learn but I can build nearly any website or application.";
  constructor() { }

  ngOnInit(): void {
    this.skillList = [
      {
        name: 'Angular',
        details: "I've been using Angular for 2 years now. Mostly using Angular 8. I know about the data flow and immutability, attributes and directive, component life cycle observable, presenters and containers, routing or pipes... I still got a lot to learn but I can build nearly any website or application.",
        showDetails: false,
        icon: 'fab fa-angular',
      },
      {
        name: 'RxJS',
        details: 'When using Angular to develop a website or an Application, you have to use RxJS so you can work better with observable or observers. Some of RxJS operators are still mysterious for me, but I can do basic stream manipulations using map, mergeMap, combineLatest, switchMap etc...',
        showDetails: false,
        icon: 'fas fa-prescription'
      },
      {
        name: 'NgRx',
        details: 'NgRx is an other must have when working with Angular. It provides a state management for creating maintainable, explicit applications trough the use of single state and actions. It makes the management of multiple data sources and user interactions way easier. As for RxJS, I know the basic, wich means creating a store, a reducer (old and new version of NgRx), some actions, and selectors.',
        showDetails: false,
        icon: 'fas fa-dot-circle',
      },
      {
        name: 'HTML5',
        details: 'HTML is always used when developing websites, and is often underestimated. I know about HTML semantic and will always use the right balise instead of filling my code with <div>',
        showDetails: false,
        icon: 'fab fa-html5'
      },
      {
        name: 'CSS/SCSS',
        details: 'I mastered the use of flex box and grids. I am focused on building responsive website and can do it using very few media queries. I like using CSS to create lively and beautiful websites using CSS Animations.',
        showDetails: false,
        icon: 'fab fa-css3'
      },
      {
        name: 'JavaScript',
        details: 'To be honest, JavaScript is here just because I know how to use TypeScript. I know there are some differences, including compilation, prototyping, inheritance or interfaces, but the syntax is kinda the same, so... Voilà !',
        showDetails: false,
        icon: 'fab fa-js'
      },
      {
        name: 'TypeScript',
        details: 'My experience with Angular made me use Typescript for 2 years aswell, making me familiar with this language. There are probably subtilities I never heard of, but I can use Typecsript to build any website or application without any problem.',
        showDetails: false,
        icon: 'fab fa-js'
      },
      {
        name: 'Git',
        details: 'I have no problem using Git. I can handle merge conflicts and I am used to work with a Git Flow',
        showDetails: false,
        icon: 'fab fa-github'
      },
      {
        name: 'RESTful API',
        details: 'During my experiences, I worked with all kind of API (facebook, twitter, discord, slack), but also internal API\s, making me used to write documentation and work with other developers to build a solid API.',
        showDetails: false,
        icon: 'fas fa-exchange-alt'
      },
      {
        name: 'Search optimization',
        details: 'When building a website, it\'s very important to have a good semantic so the website can show high in google searches',
        showDetails: false,
        icon: 'fab fa-google'
      },
      {
        name: 'AGIL',
        details: 'My biggest strength are my communication with my pairs and the organisation of my projects. I am used to write documentation for every page I do. I am also used to always plan before starting building a website, be it the UX or the UI, everything is decided before I start coding.\n Working with agile methods like SCRUM or Kanban',
        showDetails: false,
        icon: 'fas fa-comment'
      }
    ]
  }
}
