import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampCarouselComponent } from './champ-carousel.component';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@NgModule({
  declarations: [ChampCarouselComponent],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    GalleriaModule,
  ],
  exports: [ChampCarouselComponent]
})
export class ChampCarouselModule { }
